import React from "react"
import {
  Layout,
  FlexRow,
  Parallax,
  NewsletterSection,
} from "@components/layout"
import { AnimatedLink } from "@components/shared"

import { ProductItem, CategoryItem } from "@components/ecommerce"

import easy from "../standard/img/gladiators-nevbie.png"
import mid from "../standard/img/gladiators-junior.png"
import midEasy from "../standard/img/gladiators-mid-junior.png"
import extra from "../standard/img/gladiators-extra.png"
import coffe from "../standard/img/coffee-cup-hot.png"

import {
  wave,
  shop__category__wrapper,
  shop__products__wrapper,
} from "./styles/page.module.scss"

import {
  attention__wrapper,
  attention__singleItem,
  attention__icons,
} from "./styles/page.module.scss"

const Wave = () => {
  return (
    <div className={wave}>
      <svg viewBox="0 0 1200.49 450">
        <path
          d="M0,450c-7.08-19.12,193.71-168.42,624-63.22,431.1,105.4,584.2-178.43,576-170.61V450Z"
          transform="translate(0 0)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}

const renderWithAllProps = (Comp) => (entry, key) => {
  return <Comp {...entry} key={key} />
}

const Shop = ({ pageContext, location }) => {
  const { page, products, categories } = pageContext
  const shuffledProducts = products.sort(() => Math.random() - 0.5)
  const shuffledCategories = categories.sort(() => Math.random() - 0.5)

  return (
    <Layout {...page}>
      <Parallax height={450} image={page.featuredImage}>
        <h1>
          Pomoce dla młodych programistów i <small>mentoring</small>
        </h1>
        <Wave />
      </Parallax>

      <div className={attention__wrapper}>
        <div className={attention__singleItem}>
          <div className={attention__icons}>
            <img src={easy} />
            <img src={mid} alt="" />
          </div>
          <div>
            <h2>Zbiory zadań</h2>
            <p>
              Każdy zbiór pozwoli Ci przećwiczyć 130% zagadnień z dziedziny JS,
              niezależnie od poziomu na którym obecnie znajdują się Twoje
              umiejętności. Jeśli poszukujesz inspiracji do projektu lub nowych
              wyzwań - to wypatruj czarnej księgi
            </p>
            <AnimatedLink to={`/shop/categories/zbiory-zadan/`}>
              Znajdź zadania dla siebie
            </AnimatedLink>
          </div>
          <div className={attention__icons}>
            <img src={midEasy} />
            <img src={extra} alt="" />
          </div>
        </div>

        <div className={attention__singleItem}>
          <div>
            <img src={coffe} />
          </div>
          <div>
            <h2>Wsparcie inicjatyw dla programistów</h2>
            <p>
              W tym miejscu możesz wesprzeć inicjatywy non-profit, które
              współtworzę kupując kawę lub dwie, a ja zamienię tą kawę w nowe
              posty, opisane materiały i bardziej pomocne w rozwoju zadania dla
              młodych programistów
            </p>
            <AnimatedLink
              to={`/shop/products/postaw-mocna-kawe-tylko-dla-programistow/`}
            >
              Robisz dobrą robotę, stawiam kawę!
            </AnimatedLink>
          </div>
        </div>
      </div>

      <div className={shop__category__wrapper}>
        {shuffledCategories.map(renderWithAllProps(CategoryItem))}
      </div>

      <NewsletterSection />

      <div className={shop__products__wrapper}>
        {shuffledProducts.map(renderWithAllProps(ProductItem))}
      </div>

      {/*
			<LastSeenProductsSection />
			<ProductsSection recommended={true} products={pageContext.productsWithDetails} maxCount={12}
			button={false}
			/>
			<CategoriesSection categories={pageContext.productCategories} slider={false} />
			*/}
    </Layout>
  )
}

export default Shop
